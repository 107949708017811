import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import Head from "../components/Head"
import styled from "styled-components"
import Services from "../components/Services"
import services from "../../data/services"
import bgImg from "../../assets/images/newAll.png"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"

const E = {
  ContentText: styled.div`
    overflow: hidden;
    z-index: 2;
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 40px 5px 40px 40px;
    padding: 30px;
    margin: 40px 0;

    @media (min-width: ${breakpoints.large}px) {
      font-size: 1.1rem;
    }
  `,

  List: styled.ul`
    margin-top: 20px;
    list-style: none;

    li {
      position: relative;
      padding: 6px 0;
      padding-right: 30px;

      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 14px;
        float: left;
        border-radius: 100px;
        border-top-right-radius: 0;
        text-align: center;
        background: #11416b;
        color: #fff;
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 8px;
      }
    }
  `,
}

const AboutPage = () => (
  <Layout>
    <Seo title="אודות" />
    <Head title="אודותינו" img={bgImg} className="heads" />

    <section>
      <div className="container">
        <E.ContentText>
          <p>
            פולאריס - הוקמה על ידי אמיר הסל, עומר הרם וודים טרבלסקי, מנהלים
            בכירים בחברות ביטוח ובתי השקעות בישראל, עם ניסיון מצטבר של עשרות
            שנים בתחומי השקעות, פרישה, מיסוי וביטוח.
            <br />
            הרעיון להקים את פולאריס עלה לאור המורכבות הגדולה של שילוב התחומים
            הבאים תוך ראיה כוללת:
          </p>

          <E.List>
            <li>ניהול השקעות</li>
            <li>ניהול חסכונות פנסיוניים לפרישה</li>
            <li>ניהול והבנת הגנות ביטוחיות</li>
            <li>תכנון מס</li>
            <li>ניהול תהליך הפרישה</li>
          </E.List>
        </E.ContentText>

        <E.ContentText>
          <p>
            פולאריס מספקת שירותי תכנון פיננסי אישי/ משפחתי תוך התייחסות לכל
            מוצרי החיסכון של הלקוח: הן החסכונות הפנסיוניים והן החסכונות הפרטיים,
            מתוך כוונה לייעל את התיק הכולל ולספק ללקוח ערך מוסף אמיתי.
            <br />
            קהל לקוחותינו מורכב מלקוחות בשכבות גיל שונות ובעלי משלח יד מגוון,
            שכירים בחברות מובילות במשק ועצמאים במגזרים שונים, עובדים פעילים
            ועובדים לפני פרישה ואף כאלה שכבר יצאו לגמלאות.
            <br />
            לכל אנשי הצוות קשר הדוק ומתמשך עם לקוחותיהם הבא לידי ביטוי בשיחות
            תכופות, מענה מיידי לכל בקשה של הלקוח, ופגישות תקופתיות.
          </p>
          <p>
            <b>
              פולאריס מנווט אותך להחלטות פיננסיות נכונות – כך לא תלכו לאיבוד.
            </b>
          </p>
        </E.ContentText>
      </div>
    </section>
    <section className="colored">
      <Services services={services} />
    </section>
  </Layout>
)

export default AboutPage
